import SlimSelect from 'slim-select';
import './language-switcher.scss';

class LanguageSwitcher {
    constructor(element, options) {
        const settings = {
            initAttr: 'data-language-switcher'
        };

        this.settings = Object.assign({}, settings, options);
        this.$languageSwitcher = element;
        this.$direction = this.$languageSwitcher.dataset.direction;

        this.languageSwitcher = new SlimSelect({
            select: this.$languageSwitcher.querySelector('select'),
            showSearch: false,
            showContent: this.$direction
        });

        this.initialize();
    }

    initialize() {
        this.$languageSwitcher.addEventListener('change', function (e) {
            window.location.href = e.target.value;
        });
    }
}

export { LanguageSwitcher };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $languageSwitchers = $context.querySelectorAll(
            '[data-language-switcher="root"]'
        );

        [].slice.call($languageSwitchers).forEach(($languageSwitcher) => {
            const api = new LanguageSwitcher($languageSwitcher);
            api.API = $languageSwitcher;
        });
    }
});
